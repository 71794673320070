import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
          <i className="wi wi-day-sunny"></i>
        </div>
        <p>Yet another weather app</p>
      </header>
    </div>
  );
}

export default App;
